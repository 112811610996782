import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/borden/layout';
import SEO from '../components/seo';
import { parseHtmlImages, replacePortletGuids } from '../components/utitlies/utility';

function BordenHome({ data }) {
  // console.log('data', data);
  const publicContent = data.medcoePublicContentJson;
  const docNames = data.allMedcoeDocNameJson.edges;
  // console.log('**** - CONTENT:', publicContent.PageContent);
  // console.log('doc names', docNames);

  let newHtml = parseHtmlImages(publicContent.PageContent, docNames);

  newHtml = replacePortletGuids(newHtml, data.allMedcoePublicContentJson.edges);
  // 1. `gatsby-node.js` add borden IDs
  // 2. Determine Layout
  const bordenBookPortletIDs = [
    63,
    64,
    65,
    66,
    67,
    68,
    69,
    70,
    71,
    72,
    73,
    74,
    75,
    77,
    78,
    79,
    80,
    81,
    82,
    83,
    84,
    85,
    86,
    87,
    106,
    107,
    108,
    109,
    110,
    111,
    112,
    113,
    114,
    115,
    116,
    119,
    120,
    121,
    123,
    124,
    125,
    129,
    130,
    132,
    134,
    135,
    138,
    139,
    142,
    144,
    148,
    150,
    151,
    153,
    154,
    155,
    162,
    163,
    168,
    175,
    182,
    183,
    188,
    189,
    191,
    192,
    193,
    194,
    197,
    198,
    199,
    1247,
    1250,
    1312,
    1327,
    1268,
    1328,
  ];
  const isHome = publicContent.PortletID === 50;
  const isBookList =
    publicContent.PortletID === 52 ||
    publicContent.PortletID === 53 ||
    publicContent.PortletID === 59;
  const isBookDetail = bordenBookPortletIDs.includes(publicContent.PortletID);

  if (isHome) {
    return (
      <Layout isHome>
        <SEO title='Borden Institute' />
        <div dangerouslySetInnerHTML={{ __html: newHtml }} />
      </Layout>
    );
  } else if (isBookList) {
    return (
      <Layout docNames={docNames} isBookList portletID={publicContent.PortletID}>
        <SEO title='Borden Institute' />
        <div dangerouslySetInnerHTML={{ __html: newHtml }} />
      </Layout>
    );
  } else if (isBookDetail) {
    return (
      <Layout docNames={docNames} isBookDetail portletID={publicContent.PortletID}>
        <SEO title='Borden Institute' />
        <div dangerouslySetInnerHTML={{ __html: newHtml }} />
      </Layout>
    );
  } else {
    return (
      <Layout>
        <SEO title='Borden Institute' />
        <div dangerouslySetInnerHTML={{ __html: newHtml }} />
      </Layout>
    );
  }
}
// TODO: Make this into a custom hook
export const query = graphql`
  query BordenHome($id: Int) {
    medcoePublicContentJson(PortletID: { eq: $id }) {
      PortletID
      PageContent
      PortletName
    }
    allFile {
      edges {
        node {
          base
          publicURL
        }
      }
    }
    allMedcoeDocNameJson {
      edges {
        node {
          document_name
          id
          docGuid
        }
      }
    }
    allMedcoePublicContentJson {
      edges {
        node {
          PortletName
          PortletGuid
        }
      }
    }
  }
`;

export default BordenHome;
